/*
 * @Author: zhaoyang
 * @Date: 2024-11-14 11:02:35
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-14 14:41:52
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { getHost } from 'src/common/constant/config';
const api = {
    getHost,
    passwordSend: customGet('/api/user/forget/password/send'),
    passwordConfirm: customPost('/api/user/forget/password/confirm')
};
export default assign(api);
