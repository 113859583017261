var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "register-layout",
    {
      scopedSlots: _vm._u([
        {
          key: "tip",
          fn: function () {
            return [
              _c("div", { staticClass: "tip" }, [
                _c("span", [_vm._v("以下に該当するお客様は、")]),
                _vm._v(" "),
                _c("span", { staticClass: "red" }, [
                  _vm._v("ご利用になれません。"),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("予めご了承ください")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.tipList, function (item) {
                return _c("div", { key: item, staticClass: "tip-list c-09" }, [
                  _vm._v("\n            " + _vm._s(item) + "\n        "),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "b3 c-09",
                  staticStyle: { "margin-top": "0.15rem" },
                },
                [
                  _vm._v(
                    "\n            こ不明点等がありましたらお間い合わせよりこ連絡下さい。\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "register-left" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            "\n            以下のフォームから会員仮登録のお申し込みができます\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            "\n            仮登録完了後ご入力頂いたメールアドレスへ本会員登録のご案内を配信します。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc", staticStyle: { "margin-top": "0" } }, [
          _vm._v(
            "\n            ※自動配信メールが届かない場合は、「インスタキャッシュ」のドメイン（instachsh.jp）を許可する設定になっているかご確認ください。\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sub-tip-wrap" },
          [
            _c("span", { staticClass: "fa-ren" }, [
              _vm._v("🪧 法人のお客様へ"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.tipSub, function (txt) {
              return _c("div", { key: txt, staticClass: "sub-tip" }, [
                _vm._v("\n                " + _vm._s(txt) + "\n            "),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _c("span", [_vm._v("会員登録にはこちらのブラウザを推奨します。")]),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("./img/safari.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("./img/chrome.png"), alt: "" },
          }),
        ]),
        _vm._v(" "),
        !_vm.isServer && _vm.form
          ? _c(
              "div",
              { staticClass: "form-wrap" },
              [
                _c(
                  "a-form",
                  {
                    staticClass: "form",
                    attrs: { layout: "vertical", form: _vm.form },
                  },
                  [
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { width: "53%" },
                        attrs: { label: "メールアドレス" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "email",
                                { rules: _vm.getRules("email") },
                              ],
                              expression:
                                "['email', {rules: getRules('email')}]",
                            },
                          ],
                          staticStyle: { width: "92%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { width: "47%" },
                        attrs: { label: "メールアドレス確認" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "emailMust",
                                { rules: _vm.getRules("emailMust") },
                              ],
                              expression:
                                "['emailMust', {rules: getRules('emailMust')}]",
                            },
                          ],
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { width: "53%" },
                        attrs: { label: "パスワード" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "password",
                                { rules: _vm.getRules("password") },
                              ],
                              expression:
                                "['password', {rules: getRules('password')}]",
                            },
                          ],
                          staticStyle: { width: "92%" },
                          attrs: { type: "password" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { width: "47%" },
                        attrs: { label: "パスワード確認" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "passwordMust",
                                { rules: _vm.getRules("passwordMust") },
                              ],
                              expression:
                                "['passwordMust', {rules: getRules('passwordMust')}]",
                            },
                          ],
                          attrs: { type: "password" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { width: "53%" },
                        attrs: { label: "秘密の質問" },
                      },
                      [
                        _c(
                          "a-radio-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "pwdQuestion",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "必須項目を記入してください。",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'pwdQuestion',\n                            { rules: [{ required: true, message: '必須項目を記入してください。' }] },\n                        ]",
                              },
                            ],
                            staticClass: "custom-btn-radio-group",
                            staticStyle: { width: "92%" },
                          },
                          _vm._l(_vm.pwdQuestionEnum, function (label, key) {
                            return _c(
                              "a-radio-button",
                              { key: key, attrs: { value: key } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(label) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { width: "47%" },
                        attrs: { label: "その答え" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "pwdAnswer",
                                { rules: _vm.getRules("pwdAnswer") },
                              ],
                              expression:
                                "['pwdAnswer', {rules: getRules('pwdAnswer')}]",
                            },
                          ],
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "agree-title" }, [
                  _c("span", { staticClass: "title-left" }, [
                    _vm._v("以下の同意事項を"),
                  ]),
                  _c("span", { staticClass: "title-right" }, [
                    _vm._v("お読みいただき、同意してください。"),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.agreementList, function (item, idx) {
                  return _c(
                    "div",
                    { key: idx, attrs: { info: item } },
                    [
                      _c("agreement", {
                        ref: "agreement",
                        refInFor: true,
                        staticStyle: { "margin-bottom": "0.33rem" },
                        attrs: { info: item },
                        on: {
                          check: function ($event) {
                            return _vm.onCheck(idx)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "btn active",
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("\n                送信\n            ")]
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }