<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-25 13:34:45 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 17:43:44 -->

<template>
    <div class="container about-us">
        <h1 class="bold">
            会社概要
        </h1>
        <div class="intro">
            <div class="des-group">
                <div
                    v-for="([label, value], idx) in infos"
                    :key="idx"
                    class="des-group-row b3"
                >
                    <div class="des-group-row-label bold">
                        {{ label }}
                    </div>
                    <div class="des-group-row-value">
                        {{ value }}
                    </div>
                </div>
            </div>
            <p class="b2">
                一般社団法人第二種金融商品取引業協会加入
            </p>
            <p class="b2">
                一般社団法人第二種金融商品取引業協会を通じて契約する指定紛争解決機関<br>
                特定非営利活動法人証券・金融商品あっせん相談センター<br>
                東京都中央区日本橋茅場町2-1-1第二証券会館<br>
                電話番号：0120－64－5005<br>
                受付け時間：土日祝日を除く9:00～17:00
            </p>
            <p class="b1 bold">
                沿革
            </p>
            <p class="b2">
                平成29年8月22日 会社設立<br>
                令和6年10月10日金融商品取引業登録 関東財務局長（金商）第3435号
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',

    data() {
        return {
            infos: [
                ['商号', 'インスタキャッシュ株式会社'],
                ['代表取締役', '湯浅健一'],
                ['Eメ-ル', 'info@instacash.jp']
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.about-us {
    display: flex;
    padding: 1rem 0;

    h1 {
        color: $c-01;
    }

    .intro {
        margin-left: 1.84rem;
        width: 5.52rem;

        .b1 {
            margin-top: 0.8rem;
            color: $c-01;
        }

        .b2 {
            margin-top: 0.14rem;
            color: $c-03;
        }

        .first-p {
            margin: 0.48rem 0;
        }

        .des-group-row-label {
            white-space: nowrap;
        }
    }
}
</style>
