/*
 * @Author: zhaoyang
 * @Date: 2024-02-24 14:38:23
 * @Last Modified by: yuanyuanliu
 * @Last Modified time: 2024-12-26 17:41:35
 */

import {Form, Input, Checkbox, Radio, DatePicker, Upload, Message, List, Spin, Select} from 'ant-design-vue';

export default {
    install(Vue) {
        Vue.use(Form);
        Vue.use(Input);
        Vue.use(Checkbox);
        Vue.use(DatePicker);
        Vue.use(Upload);
        Vue.use(Radio);
        Vue.use(List);
        Vue.use(Spin);
        Vue.use(Select);
        Message.config({
            duration: 5,
        });
        const {error, success} = Message;
        Vue.prototype.$error = error;
        Vue.prototype.$success = success;
    }
};
