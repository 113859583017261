<!--
 * @Author: zhaoyang
 * @Date: 2024-11-14 16:46:03
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-20 19:14:23
-->

<template>
    <div class="success-box">
        <div class="success-left">
            <div class="title">
                パスワードのリセットが成功しました
            </div>
            <div class="sub-title">
                パスワードのリセットに成功しました。新しいパスワードを使用して再度ログインしてください
            </div>
            <div
                class="btn"
                @click="onGo"
            >
                ログイン
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ChangeSucc',

    methods: {
        onGo() {
            this.$router.replace('Login');
        }
    }
};
</script>
<style lang="scss" scoped>
.success-box {
    flex: 1;
    display: flex;

    &::after {
        content: "";
        display: block;
        flex: 1;
        background: url("./img/group.jpg") no-repeat center / cover;
        padding-top: 58.2%;
    }
}

.success-left {
    flex: 0 1 50%;
    font-family: "Noto Sans JP";
    font-size: 0.14rem;
    font-weight: 400;
    line-height: 0.22rem;
    color: $c-02;
    padding: 1.6rem 1.6rem 2.4rem 1.2rem;

    .title {
        font-family: "Noto serif JP";
        font-size: 0.32rem;
        font-weight: 500;
        line-height: 0.42rem;
        color: $c-01;
    }

    .sub-title {
        font-family: "Noto Sans JP";
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.25rem;
        color: #48484e;
        margin-top: 0.24rem;
    }

    .btn {
        border-radius: 0.12rem;
        margin-top: 0.73rem;
        width: 3rem;
        height: 0.52rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Noto Sans JP";
        font-size: 0.16rem;
        font-weight: 700;
        line-height: 1;
        cursor: pointer;
        color: $c-09;
        background-color: $c-01;
    }
}
</style>
