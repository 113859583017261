var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "index" }, [
      _c("div", { staticClass: "banner bold" }, [
        _c("p", [
          _vm._v(
            "\n            当社はお客様に新たな投資機会を提供し、\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n            お客様の資産形成に貢献いたします。\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h1", { staticClass: "mt100" }, [
          _vm._v("\n            インスタキャッシュについて\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt53" }, [
          _c("div", { staticClass: "w-card row-item mr20 c-04" }, [
            _c("div", { staticClass: "w-card-icon" }, [
              _c("img", {
                staticClass: "w-card-icon-inner",
                attrs: { src: require("./img/icon1.png") },
              }),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "mt60" }, [
              _vm._v("\n                    会社概要\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-04" }, [
              _c("p", { staticClass: "b2 mt16" }, [
                _vm._v(
                  "\n                        Instacash株式会社\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        第二種金融商品取引業者：関東財務局長（金商）第3435号\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        ヒューリック渋谷一丁目ビル 624室 info@instcash.jp\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        〒150-0002 東京都渋谷区渋谷1-3-9\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        代表取締役 湯浅健一\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        資本金 9,000万円\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        加入協会 一般社団法人第二種金融商品取引業協会\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-card row-item mr20" }, [
            _c("div", { staticClass: "w-card-icon" }, [
              _c("img", {
                staticClass: "w-card-icon-inner",
                attrs: { src: require("./img/icon2.png") },
              }),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "mt60" }, [
              _vm._v(
                "\n                    当社グループについて\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2 mt16 c-04" }, [
              _vm._v(
                "\n                    当社は世界各国で活動するFintopiaグループに属します。\n                    Fintopiaは中国の大学同期生、劉延永と耿博がシリコンバレーで再会したことがきっかけで誕生しました（劉はGoogle、後にFacebok、耿はマイクロソフト）。二人は帰国後、金銭の貸手借手をマッチングするプラットホーム事業で起業、他国にも事業を拡大してきました。AI技術をもとに開発したクレジット・スコアリングモデルが貢献しております。\n                "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-card mt20 mr20" }, [
          _c("div", { staticClass: "w-card-icon" }, [
            _c("img", {
              staticClass: "w-card-icon-inner",
              attrs: { src: require("./img/icon3.png") },
            }),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mt60" }, [
            _vm._v("\n                代表者挨拶\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-04" }, [
            _c("p", { staticClass: "b2 mt16" }, [
              _vm._v(
                "\n                    代表取締役の湯浅と申します。当社は金融商品を通じお客様の資産形成に貢献いたします。どのような金融商品かと申しますと、貸付事業等権利への出資証券です。従来は「「貸付型ファンド」と言われておりました。株式や債券といった伝統的な投資対象とは異なるオルタナティブな投資機会です。\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2" }, [
              _vm._v(
                "\n                    当社グループは海外国における貸付事業で実績を積んできました。金融商品を通じ当該事業にご案内し、お客様の資産形成や運用リスク分散の一助となれれば幸いです。\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2" }, [
              _vm._v(
                "\n                    サービス提供まで暫くお待ちいただきますが、どうぞ宜しくお願いいたします。\n                "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "mission" }, [
        _c("h1", { staticClass: "c-09" }, [
          _vm._v("\n            沿革\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt37" }, [
          _c("span", { staticClass: "b2 bold" }, [
            _vm._v("\n                2017\n            "),
          ]),
          _vm._v("\n            --------\n            "),
          _c("span", { staticClass: "b2" }, [
            _vm._v("\n                2025\n            "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-card" }, [
          _c("div", { staticClass: "row-between" }, [
            _c("div", { staticClass: "year" }, [
              _vm._v("\n                    2025\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "text-align": "right" } }, [
              _c("h3", [
                _vm._v(
                  "\n                        2024年10月 第二種金融商品取引業登録\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "\n                        2025年1月  一般社団法人 第二種金融商品取引業協会加入\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }