/*
 * @Author: zhaoyang
 * @Date: 2024-11-07 20:26:47
 * @Last Modified by: yuanyuanliu
 * @Last Modified time: 2024-12-17 16:12:25
 */

import AboutUs from 'instacash/app/finance/about-us';
import ChangePwd from 'instacash/app/finance/change-pwd';
import ChangeSucc from 'instacash/app/finance/change-pwd/success';
import EmailModify from 'instacash/app/finance/email-modify';
import EmailModifyRecord from 'instacash/app/finance/email-modify/record';
import Login from 'instacash/app/finance/login';
import MessageBoard from 'instacash/app/finance/message-board';
import NotificationList from 'instacash/app/finance/notification';
import Product from 'instacash/app/finance/product';
import ProductHold from 'instacash/app/finance/product/hold';
import ProductList from 'instacash/app/finance/product/list';
import ProductRecord from 'instacash/app/finance/product/record';
import Profile from 'instacash/app/finance/profile';
import ProfileModify from 'instacash/app/finance/profile/modify';
import FinanceRegisterStep1 from 'instacash/app/finance/register/step1';
import RegisterSendEmail from 'instacash/app/finance/register/step1/send-email';
import FinanceRegisterStep2 from 'instacash/app/finance/register/step2';
import FinanceRegisterStep2Upload from 'instacash/app/finance/register/step2/upload';
import FinanceRegisterStep3 from 'instacash/app/finance/register/step3';
import FinanceRegisterKycCode from 'instacash/app/finance/register/step3/kyc-code';
import TradeRecord from 'instacash/app/finance/trade-record';
import Home from 'instacash/app/home';

export default [
    {
        path: '/',
        name: 'Index',
        component: Home
    },
    {
        path: '/product/:id',
        name: 'Product',
        component: Product
    },
    {
        path: '/product-list',
        name: 'ProductList',
        component: ProductList
    },
    {
        path: '/product-hold',
        name: 'ProductHold',
        component: ProductHold,
        meta: {
            needLogin: true
        }
    },
    {
        path: '/product-record',
        name: 'ProductRecord',
        component: ProductRecord,
        meta: {
            needLogin: true
        }
    },
    {
        path: '/register/step1/1',
        name: 'RegisterStep1-1',
        component: FinanceRegisterStep1,
        meta: {
            hideNav: true
        }
    },
    {
        path: '/register/step1/2',
        name: 'RegisterStep1-2',
        component: RegisterSendEmail,
        meta: {
            hideNav: true,
            needLogin: true
        }
    },
    {
        path: '/register/step2/1',
        name: 'RegisterStep2-1',
        component: FinanceRegisterStep2,
        meta: {
            hideNav: true,
            needLogin: true
        }
    },
    {
        path: '/register/step2/2',
        name: 'RegisterStep2-2',
        component: FinanceRegisterStep2Upload,
        meta: {
            hideNav: true,
            needLogin: true
        }
    },
    {
        path: '/register/step3',
        name: 'RegisterStep3',
        component: FinanceRegisterStep3,
        meta: {
            hideNav: true,
            needLogin: true
        }
    },
    {
        path: '/register/kyc-code',
        name: 'RegisterKycCode',
        component: FinanceRegisterKycCode,
        meta: {
            hideNav: true,
            needLogin: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            needLogin: true
        }
    },
    {
        path: '/trade-record',
        name: 'TradeRecord',
        component: TradeRecord,
        meta: {
            needLogin: true
        }
    },
    {
        path: '/notification-list',
        name: 'NotificationList',
        component: NotificationList
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/message-board',
        name: 'MessageBoard',
        component: MessageBoard,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            hideNav: true,
        }
    },
    {
        path: '/change-pwd',
        name: 'ChangePwd',
        component: ChangePwd,
        meta: {
            hideNav: true,
        }
    },
    {
        path: '/change-succ',
        name: 'ChangeSucc',
        component: ChangeSucc,
        meta: {
            hideNav: true,
        }
    },
    {
        path: '/profile-modify',
        name: 'ProfileModify',
        component: ProfileModify,
        meta: {
            needLogin: true,
        }
    },
    {
        path: '/email-modify',
        name: 'EmailModify',
        component: EmailModify,
        meta: {
            hideNav: true,
            needLogin: true,
        }
    },
    {
        path: '/email-modify/record',
        name: 'EmailModifyRecord',
        component: EmailModifyRecord,
        meta: {
            hideNav: true,
            needLogin: true,
        }
    },
    {
        path: '*',
        redirect: {name: 'Index'}
    }
];
