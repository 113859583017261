<!-- @Author: huafengli -->
<!-- @Date: 2024-7-10 10:53:02 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-10 11:11:27 -->

<template>
    <div class="email-container">
        <div class="left">
            <h2 class="mb24">
                メールアドレス変更
            </h2>
            <p class="b3">
                登録された新しいメールアドレスに変更処理完了用のメールを送信します。メール内に記載されているリンクをクリックして表示される画面から変更処理の完了手続きを行うとメールアドレスが変更されます。
            </p>
            <div
                v-if="form"
                class="form-wrap"
            >
                <a-form
                    class="form"
                    layout="vertical"
                    :form="form"
                >
                    <a-form-item label="新しいメールアドレス">
                        <a-input
                            v-decorator="['email', {
                                rules: [
                                    {required: true, message: ' '},
                                    {type: 'email', message: 'メールのフォーマットが正しくありません'}]
                            }]"
                            placeholder="メールアドレスを入力してください"
                        />
                    </a-form-item>
                    <a-form-item label="新しいメールアドレス（確認）">
                        <a-input
                            v-decorator="['emailMust', {
                                rules: [
                                    {required: true, message: ' '},
                                    {type: 'email', message: 'メールのフォーマットが正しくありません'},
                                    {validator: isEqualEmailValidator}
                                ],
                                validateFirst: true
                            }]"
                            placeholder="メールアドレスを入力してください"
                        />
                    </a-form-item>
                    <div
                        class="btn b2"
                        :class="{active: canSubmit}"
                        @click="onSubmit"
                    >
                        変更申請
                    </div>
                </a-form>
            </div>
        </div>
        <img
            src="../login/img/group.jpg"
            alt="group"
            class="right"
        >
    </div>
</template>
<script>
import User from 'instacash/common/resource/user';

export default {
    name: 'EmailModify',

    data() {
        return {
            canSubmit: false,
            form: null,
        };
    },

    mounted() {
        const {onValuesChange} = this;
        this.form = this.$form.createForm(this, {name: 'email', onValuesChange});
    },

    methods: {
        isEqualEmailValidator(rule, value, callback) {
            if (value && value !== this.form.getFieldValue('email')) {
                return callback('メールアドレスの値が確認の値と一致しません。');
            }

            return callback();
        },

        async onValuesChange() {
            this.$nextTick(() => {
                this.form.validateFields(err => {
                    this.canSubmit = !err;
                });
            });
        },

        onSubmit() {
            if (!this.canSubmit) {
                return;
            }

            this.form.validateFields(async (err, values) => {
                if (err) {
                    return;
                }

                await User.updateEmail({params: {email: values.email}});
                this.$success('お客様の修正内容が更新されました。');

                this.$router.push({name: 'EmailModifyRecord'});
            });
        }
    },

};
</script>
<style lang="scss" scoped>
    .email-container {
        flex: 1;
        display: flex;

        .left {
            flex: 0 1 50%;
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: $c-02;
            padding: 1.6rem 1.2rem 2.1rem;

            .form-wrap {
                margin-top: 0.6rem;

                .form {
                    display: flex;
                    flex-direction: column;

                    .btn {
                        border-radius: 0.12rem;
                        margin-top: 0.6rem;
                        width: 3rem;
                        height: 0.52rem;
                        background-color: $c-06;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        cursor: pointer;
                    }

                    .active {
                        color: $c-09;
                        background-color: $c-01;
                    }
                }
            }
        }

        .right {
            width: 7.2rem;
        }
    }

    .mb24 {
        margin-bottom: 0.24rem;
    }
</style>
