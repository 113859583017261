/*
 * @Author: yuanyuanliu
 * @Date: 2024-12-17 18:12:59
 * @Last Modified by: yuanyuanliu
 * @Last Modified time: 2024-12-17 18:14:45
 */
import { assign, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/api/ask/message',
    submit: customPost('submit')
};
export default assign(api);
