<!--
 * @Author: zhaoyang
 * @Date: 2024-02-24 12:42:20
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-13 16:26:57
-->

<template>
    <register-layout>
        <div class="register-left">
            <div class="title">
                以下のフォームから会員仮登録のお申し込みができます
            </div>

            <div class="desc">
                仮登録完了後ご入力頂いたメールアドレスへ本会員登録のご案内を配信します。
            </div>
            <div
                class="desc"
                style="margin-top: 0;"
            >
                ※自動配信メールが届かない場合は、「インスタキャッシュ」のドメイン（instachsh.jp）を許可する設定になっているかご確認ください。
            </div>

            <div class="sub-tip-wrap">
                <span class="fa-ren">🪧 法人のお客様へ</span>
                <div
                    v-for="txt in tipSub"
                    :key="txt"
                    class="sub-tip"
                >
                    {{ txt }}
                </div>
            </div>

            <div class="desc">
                <span>会員登録にはこちらのブラウザを推奨します。</span>
                <img
                    src="./img/safari.png"
                    alt=""
                    class="icon"
                >
                <img
                    src="./img/chrome.png"
                    alt=""
                    class="icon"
                >
            </div>

            <div
                v-if="!isServer && form"
                class="form-wrap"
            >
                <a-form
                    class="form"
                    layout="vertical"
                    :form="form"
                >
                    <a-form-item
                        label="メールアドレス"
                        style="width: 53%;"
                    >
                        <a-input
                            v-decorator="['email', {rules: getRules('email')}]"
                            style="width: 92%;"
                        />
                    </a-form-item>
                    <a-form-item
                        label="メールアドレス確認"
                        style="width: 47%;"
                    >
                        <a-input
                            v-decorator="['emailMust', {rules: getRules('emailMust')}]"
                        />
                    </a-form-item>
                    <a-form-item
                        label="パスワード"
                        style="width: 53%;"
                    >
                        <a-input
                            v-decorator="['password', {rules: getRules('password')}]"
                            type="password"
                            style="width: 92%;"
                        />
                    </a-form-item>
                    <a-form-item
                        label="パスワード確認"
                        style="width: 47%;"
                    >
                        <a-input
                            v-decorator="['passwordMust', {rules: getRules('passwordMust')}]"
                            type="password"
                        />
                    </a-form-item>

                    <a-form-item
                        label="秘密の質問"
                        style="width: 53%;"
                    >
                        <a-radio-group
                            v-decorator="[
                                'pwdQuestion',
                                { rules: [{ required: true, message: '必須項目を記入してください。' }] },
                            ]"
                            class="custom-btn-radio-group"
                            style="width: 92%;"
                        >
                            <a-radio-button
                                v-for="(label, key) in pwdQuestionEnum"
                                :key="key"
                                :value="key"
                            >
                                {{ label }}
                            </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item
                        label="その答え"
                        style="width: 47%;"
                    >
                        <a-input
                            v-decorator="['pwdAnswer', {rules: getRules('pwdAnswer')}]"
                        />
                    </a-form-item>
                </a-form>
                <div class="agree-title">
                    <span class="title-left">以下の同意事項を</span><span class="title-right">お読みいただき、同意してください。</span>
                </div>

                <div
                    v-for="(item, idx) in agreementList"
                    :key="idx"
                    :info="item"
                >
                    <agreement
                        ref="agreement"
                        style="margin-bottom: 0.33rem;"
                        :info="item"
                        @check="onCheck(idx)"
                    />
                </div>

                <div
                    class="btn active"
                    @click="handleSubmit"
                >
                    送信
                </div>
                <!-- <div class="info">
                    <div
                        v-for="text in infoList"
                        :key="text"
                        class="info-text"
                    >
                        {{ text }}
                    </div>
                </div> -->
            </div>
        </div>
        <template #tip>
            <div class="tip">
                <span>以下に該当するお客様は、</span>
                <span class="red">ご利用になれません。</span>
                <span>予めご了承ください</span>
            </div>
            <div
                v-for="item in tipList"
                :key="item"
                class="tip-list c-09"
            >
                {{ item }}
            </div>
            <div
                class="b3 c-09"
                style="margin-top: 0.15rem;"
            >
                こ不明点等がありましたらお間い合わせよりこ連絡下さい。
            </div>
        </template>
    </register-layout>
</template>

<script>
import {isServer} from 'ssr-common/constant/config';

import {pwdQuestionEnum} from 'src/common/constant/enum';
import User from 'src/common/resource/user';
import {updateToken} from 'src/common/util';

import RegisterLayout from '../component/register-layout';
import Agreement from './component/agreement.vue';
import {agreementList} from './constant';

export default {
    name: 'RegisterStep1',

    components: {
        RegisterLayout,
        Agreement
    },

    data() {
        return {
            pwdQuestionEnum,
            tipSub: [
                '所在地が国内の法人のお客様は会員仮登録のお申し込みができます。',
                'ご連絡先の氏名及びメールアドレスを明記し履歴事項証明書（発効後３か月以内）を下記までご提出下さい。',
                'info@〇〇.jp'
            ],
            tipList: [
                'マイナンバーをお持ちでない個人の方',
                '非居住者の方',
                '20歳未満の方·75歲以上の方',
                '他の金融商品取引業者又は登録金融機関との間で紛争中の方'
            ],
            canSubmit: false,
            isServer: isServer(),
            form: null,
            formData: null,
            agreementList: agreementList.map(item => ({...item}))
        };
    },

    mounted() {
        const {onValuesChange} = this;
        this.form = this.$form?.createForm(this, {
            name: 'stepInfo',
            onValuesChange
        });
        this.$store.commit('finance/updateFinance', {
            currentStepRouteName: 'RegisterStep1-1'
        });
    },

    methods: {
        getRules(field) {
            // const emailRe = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const pwdRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,16}$/;
            // const inputRe = /^[\u4E00-\u9FBF\u3040-\u309F\u30A0-\u30FF]*$/; // 汉字+全角假名
            let rules = [];
            const isEqualValidatorGenerator = fieldKey => {
                return (rule, value, callback) => {
                    const {[fieldKey]: must} = this.formData || {};
                    if (must && value !== must) {
                        callback(' ');

                        return;
                    }

                    callback();
                };
            };

            switch (field) {
                case 'email':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {type: 'email', message: 'メールのフォーマットが正しくありません'}
                        // {validator: isEqualValidatorGenerator('emailMust'), message: 'メールアドレスの値が確認の値と一致しません。'}
                    ];
                    break;
                case 'emailMust':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {type: 'email', message: 'メールのフォーマットが正しくありません'},
                        {validator: isEqualValidatorGenerator('email'), message: 'メールアドレスの値が確認の値と一致しません。'}
                    ];
                    break;
                case 'password':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {pattern: pwdRe, message: '大文字・小文字・数字をそれぞれ1字以上入れてください。'}
                        // {validator: isEqualValidatorGenerator('passwordMust'), message: 'パスワードが違います。'}
                    ];
                    break;
                case 'passwordMust':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {pattern: pwdRe, message: '大文字・小文字・数字をそれぞれ1字以上入れてください。'},
                        {validator: isEqualValidatorGenerator('password'), message: 'パスワードが違います。'}
                    ];
                    break;
                case 'pwdAnswer':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'}
                        // {pattern: inputRe, message: '全角（漢字、ひらがな、カタカナ）でご入力ください。'}
                    ];
                    break;
                default:
            }

            return rules;
        },

        onValuesChange(form, value, values) {
            this.formData = values;
        },

        handleRead(idx) {
            this.agreementList[idx].showCheck = true;
            window.open(this.agreementList[idx].link);
        },

        onCheck(idx) {
            this.agreementList[idx].isCheck = !this.agreementList[idx].isCheck;
            this.onValuesChange(null, null, this.formData);
        },

        handleSubmit() {
            this.form.validateFieldsAndScroll({
                scroll: {offsetTop: 300},
                first: true,
                force: true
            }, async (err, values) => {
                if (err) return;

                const checkIdx = this.agreementList.findIndex(item => !item.isCheck);
                if (checkIdx > -1) {
                    try {
                        this.$refs.agreement[checkIdx].$el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    } catch (error) {
                        // ignore
                    }

                    return;
                }

                const {data: {body}} = await User.register(values);
                updateToken(body);

                this.$store.dispatch('finance/fetchInfo');
                this.$router.push({
                    name: 'RegisterStep1-2'
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .ant-radio-button-wrapper {
        height: unset;
        min-height: 32px;
    }
}

.register-left {
    max-width: 6.3rem;
    font-family: Noto Sans JP;
    font-size: 0.14rem;
    font-weight: 400;
    line-height: 0.22rem;
    color: $c-02;
    margin-bottom: 0.1rem;

    .title {
        font-family: Noto serif JP;
        font-size: 0.32rem;
        font-weight: 700;
        line-height: 0.42rem;
        color: $c-01;
    }

    .desc {
        margin-top: 0.24rem;
        display: flex;
        align-items: center;

        .icon {
            margin-left: 0.12rem;
            height: 0.36rem;
        }
    }

    .sub-tip-wrap {
        margin-top: 0.24rem;

        .fa-ren {
            margin-bottom: 0.04rem;
            display: flex;
            align-items: center;
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 700;
            line-height: 0.22rem;
            color: #32302f;
        }

        .sub-tip {
            font-family: Noto Sans JP;
            font-size: 0.12rem;
            font-weight: 400;
            line-height: 0.19rem;
            color: #32302f;
        }
    }

    .form-wrap {
        margin-top: 0.6rem;

        .form {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .agree-title {
        margin-bottom: 0.2rem;

        .title-left {
            font-family: Noto Sans JP;
            font-size: 0.16rem;
            font-weight: 400;
            line-height: 0.26rem;
        }

        .title-right {
            font-family: Noto Sans JP;
            font-size: 0.16rem;
            font-weight: 700;
            line-height: 0.26rem;
        }
    }

    .btn {
        border-radius: 0.12rem;
        margin-top: 0.4rem;
        width: 3rem;
        height: 0.52rem;
        background-color: $c-06;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Noto Sans JP;
        font-size: 0.16rem;
        font-weight: 700;
        line-height: 1;
    }

    .active {
        color: $c-09;
        background-color: $c-01;
    }

    .info {
        margin-top: 0.28rem;
        font-family: Noto Sans JP;
        font-size: 0.12rem;
        font-weight: 400;
        line-height: 0.19rem;
        color: $c-04;
    }
}

.red-circle {
    width: 0.24rem;
    height: 0.24rem;
    border-radius: 50%;
    background-color: #f66c70;
}

.tip {
    margin-bottom: 0.18rem;
    margin-top: 0.13rem;
    color: #fff;
    font-family: Noto Sans JP;
    font-size: 0.2rem;
    font-weight: 700;
    line-height: 0.26rem;

    .red {
        color: #f66c70;
    }
}

.c-09 {
    color: $c-09;
}

.tip-list {
    position: relative;
    font-family: Noto Sans JP;
    font-size: 0.18rem;
    font-weight: 400;
    line-height: 0.29rem;
    box-sizing: border-box;
    padding-left: 0.2rem;

    &::before {
        content: "・";
        color: #fff;
        font-size: 0.18rem;
        position: absolute;
        left: 0.04rem;
        top: 0;
    }
}
</style>
