<!--
 * @Author: zhaoyang
 * @Date: 2024-11-07 20:23:26
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-08 11:29:59
-->

<template>
    <div class="index">
        <div class="banner bold">
            <p>
                当社はお客様に新たな投資機会を提供し、
            </p>
            <p>
                お客様の資産形成に貢献いたします。
            </p>
        </div>

        <section>
            <h1 class="mt100">
                インスタキャッシュについて
            </h1>
            <div class="row mt53">
                <div class="w-card row-item mr20 c-04">
                    <div class="w-card-icon">
                        <img
                            class="w-card-icon-inner"
                            src="./img/icon1.png"
                        >
                    </div>
                    <h3 class="mt60">
                        会社概要
                    </h3>
                    <div class="c-04">
                        <p class="b2 mt16">
                            Instacash株式会社
                        </p>
                        <p class="b2">
                            第二種金融商品取引業者：関東財務局長（金商）第3435号
                        </p>
                        <p class="b2">
                            ヒューリック渋谷一丁目ビル 624室 info@instcash.jp
                        </p>
                        <p class="b2">
                            〒150-0002 東京都渋谷区渋谷1-3-9
                        </p>
                        <p class="b2">
                            代表取締役 湯浅健一
                        </p>
                        <p class="b2">
                            資本金 9,000万円
                        </p>
                        <p class="b2">
                            加入協会 一般社団法人第二種金融商品取引業協会
                        </p>
                    </div>
                </div>
                <div class="w-card row-item mr20">
                    <div class="w-card-icon">
                        <img
                            class="w-card-icon-inner"
                            src="./img/icon2.png"
                        >
                    </div>
                    <h3 class="mt60">
                        当社グループについて
                    </h3>
                    <p class="b2 mt16 c-04">
                        当社は世界各国で活動するFintopiaグループに属します。
                        Fintopiaは中国の大学同期生、劉延永と耿博がシリコンバレーで再会したことがきっかけで誕生しました（劉はGoogle、後にFacebok、耿はマイクロソフト）。二人は帰国後、金銭の貸手借手をマッチングするプラットホーム事業で起業、他国にも事業を拡大してきました。AI技術をもとに開発したクレジット・スコアリングモデルが貢献しております。
                    </p>
                </div>
            </div>
            <div class="w-card mt20 mr20">
                <div class="w-card-icon">
                    <img
                        class="w-card-icon-inner"
                        src="./img/icon3.png"
                    >
                </div>
                <h3 class="mt60">
                    代表者挨拶
                </h3>
                <div class="c-04">
                    <p class="b2 mt16">
                        代表取締役の湯浅と申します。当社は金融商品を通じお客様の資産形成に貢献いたします。どのような金融商品かと申しますと、貸付事業等権利への出資証券です。従来は「「貸付型ファンド」と言われておりました。株式や債券といった伝統的な投資対象とは異なるオルタナティブな投資機会です。
                    </p>
                    <p class="b2">
                        当社グループは海外国における貸付事業で実績を積んできました。金融商品を通じ当該事業にご案内し、お客様の資産形成や運用リスク分散の一助となれれば幸いです。
                    </p>
                    <p class="b2">
                        サービス提供まで暫くお待ちいただきますが、どうぞ宜しくお願いいたします。
                    </p>
                </div>
            </div>
        </section>
        <section class="mission">
            <h1 class="c-09">
                沿革
            </h1>
            <div class="mt37">
                <span class="b2 bold">
                    2017
                </span>
                --------
                <span class="b2">
                    2025
                </span>
            </div>
            <div class="m-card">
                <div class="row-between">
                    <div class="year">
                        2025
                    </div>
                    <div style="text-align: right;">
                        <h3>
                            2024年10月 第二種金融商品取引業登録
                        </h3>
                        <h3>
                            2025年1月  一般社団法人 第二種金融商品取引業協会加入
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <!--
        <section class="section3">
            <h1>
                ニュース
            </h1>
            <h3 class="mt60">
                2024年〇月〇日 金融商品取引業者登録
            </h3>
            <h3>
                2024年〇月〇日 インドネシアMFファンド第一号運用開始
            </h3>
        </section>
        -->
    </div>
</template>

<script>
export default {
    name: 'Index'
};
</script>

<style lang="scss">
@import "../../common/style/variables.scss";

body {
    margin: 0;
    font-family:
        Circular,
        Helvetica,
        Helvetica Neue,
        Arial,
        sans-serif;
}

.index {
    // margin-top: -80px;

    padding: 0.2rem;

    .mt100 {
        margin-top: 1rem;
    }

    .mt60 {
        margin-top: 0.6rem;
    }

    .mt53 {
        margin-top: 0.53rem;
    }

    .mt37 {
        margin-top: 0.37rem;
    }

    .mt20 {
        margin-top: 0.2rem;
    }

    .mr20 {
        margin-right: 0.2rem;
    }

    .mt16 {
        margin-top: 0.16rem;
    }

    .c-04 {
        color: $c-04;
    }

    .c-09 {
        color: $c-09;
    }

    .w-card {
        background-color: $c-09;
        border-radius: 0.2rem;
        padding: 0.3rem;

        &-icon {
            width: 1rem;
            height: 1rem;

            &-inner {
                width: 100%;
                height: auto;
            }
        }
    }

    .mission {
        box-sizing: border-box;
        margin: 0.86rem -0.2rem 0;
        padding: 1.2rem 0.6rem 0.6rem;
        font-family: Noto serif JP;
        color: $c-09;
        text-align: center;
        background: url("./img/mission-bg.jpg") no-repeat center / cover;

        .m-card {
            margin-top: 0.72rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 4.8rem;
            background-color: $c-09;
            border-radius: 0.2rem;
            padding: 0 0.6rem 0.61rem;

            .year {
                font-size: 1.2rem;
                color: $c-01;
            }
        }
    }

    .section3 {
        margin-top: 2.4rem;
        text-align: center;
    }

    .row {
        width: 100%;
        display: flex;

        &-item {
            flex: 1;
        }
    }

    .row-between {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .banner {
        border-radius: 0.2rem;
        box-sizing: border-box;
        padding: 0.6rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        background: url("./img/banner-new.jpg");
        background-size: 100% 100%;
        font-size: 0.56rem;
        font-family: Noto serif JP;
        width: 100%;
        height: 43vw;
        color: $c-09;
    }

    .content {
        padding: 20px 20px 0;

        // background-color: #fff;
        text-align: center;

        .content-wrap {
            padding-top: 60px;

            .content-title {
                margin-bottom: 20px;
            }
        }

        .detail {
            max-width: 700px;
            margin: auto;
            line-height: 2;
        }

        .mission-pic {
            max-width: 1200px;
            width: 100%;
            min-width: 280px;
            padding: 40px 0 60px;
        }

        @media screen and (max-width: $screen-sm) {
            padding: 0 20px;

            .content-wrap {
                padding-top: 40px;

                .content-title {
                    margin-bottom: 8px;
                }
            }

            .mission-pic {
                padding: 20px 0 30px;
            }
        }
    }

    .about-us {
        margin-top: -10vw;

        @media screen and (max-width: $screen-md) {
            margin-top: -21vw;
        }
    }
}
</style>
