/**
 * @Author: xiaodongyu
 * @Date: 2024-02-27 11:12:26
 * @Last Modified by: yuanyuanliu
 * @Last Modified time: 2024-12-24 16:52:23
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import User from 'src/common/resource/user';
import { updateToken } from '../../util';
const SAVE = 'SAVE';
const CLEAR = 'CLEAR';
const INITIAL_STATE = {
    currentStepRouteName: '',
    email: '',
    name: '',
    status: '',
    emailTokenExpire: '',
    token: '',
    step2FormData: null,
    userInfo: {}
};
export default {
    namespaced: true,
    state: () => (Object.assign({}, INITIAL_STATE)),
    getters: {
        kyc: state => state.status === 'KYC_CONFIRMED',
        emailTokenExpire: state => state.emailTokenExpire,
        email: state => state.userInfo.email,
        name: state => state.userInfo.name,
        token: state => state.token,
        status: state => state.status,
        step2FormData: state => state.step2FormData,
        userInfo: state => state.userInfo,
    },
    mutations: {
        [SAVE](state, data) {
            Object.assign(state, data);
        },
        [CLEAR](state) {
            Object.assign(state, INITIAL_STATE);
        },
    },
    actions: {
        setEmail({ commit }, data) {
            commit(SAVE, { userEmail: data });
        },
        fetchUserInfo({ commit }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data: { body } } = yield User.userInfo();
                commit(SAVE, { userInfo: body });
            });
        },
        fetchMainInfo({ commit }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data: { body } } = yield User.mainInfo({ hideLoading: true });
                commit(SAVE, body);
            });
        },
        fetchInfo({ commit, state }) {
            return __awaiter(this, void 0, void 0, function* () {
                const [mainInfo, userInfo] = yield Promise.all([
                    User.mainInfo({ hideLoading: true }).then(res => res.data.body),
                    User.userInfo({ hideLoading: true }).then(res => res.data.body)
                ]);
                commit(SAVE, Object.assign(Object.assign({}, mainInfo), { userInfo }));
            });
        },
        login({ dispatch, commit }, data) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data: { body: token } } = yield User.login(data);
                updateToken(token);
                yield Promise.all([
                    dispatch('setEmail', data.email),
                    dispatch('fetchInfo')
                ]);
            });
        },
        logout({ commit }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield User.logout();
                updateToken('');
                commit(CLEAR);
                return Promise.resolve();
            });
        }
    }
};
