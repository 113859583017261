<!-- @Author: yuanyuanliu -->
<!-- @Date: 2024-12-17 15:21:22 -->
<!-- @Last Modified by: lyy -->
<!-- @Last Modified time: 2024-12-17 18:58:11 -->
<template>
    <div class="message-box">
        <div class="message-left">
            <div
                v-if="form"
                class="form-wrap"
            >
                <a-form
                    class="form"
                    layout="vertical"
                    :form="form"
                >
                    <a-form-item
                        label="氏名（姓名）"
                    >
                        <a-input
                            v-decorator="['userName', {initialValue: initialName, rules: getRules('userName')}]"
                            :max-length="70"
                        />
                    </a-form-item>
                    <a-form-item
                        label="メールアドレス"
                    >
                        <a-input
                            v-decorator="['email', {initialValue: initialEmail, rules: getRules('email')}]"
                        />
                    </a-form-item>
                    <a-form-item label="問い合わせの種類">
                        <a-select
                            v-decorator="[
                                'askType',
                                { rules: getRules('askType') },
                            ]"
                        >
                            <a-select-option
                                v-for="item in askTypeList"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item
                        label="問い合わせの内容"
                    >
                        <a-textarea
                            v-decorator="['content', {rules: getRules('content')}]"
                            :auto-size="{ minRows: 10}"
                            :max-length="1000"
                        />
                    </a-form-item>
                    <div
                        class="btn"
                        :class="{active: canSubmit}"
                        @click="onSubmit"
                    >
                        送信する
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>
<script>

import {mapGetters} from 'vuex';

import Enum from '@yqg/enum';

import {askTypeEnum} from 'src/common/constant/enum';
import Message from 'src/common/resource/message';

export default {
    name: 'MessageBoard',

    data() {
        return {
            form: null,
            formData: null,
            canSubmit: false,
            askTypeList: [],
        };
    },

    computed: {
        ...mapGetters('finance', ['email', 'name']),

        initialEmail() {
            return this.email ?? '';
        },

        initialName() {
            return this.name ?? '';
        },
    },

    async mounted() {
        const {onValuesChange} = this;
        this.form = this.$form?.createForm(this, {
            name: 'messageBoard',
            onValuesChange
        });

        const {data: {body}} = await askTypeEnum.query();
        this.askTypeList = Enum.from(body || {})?.LIST || [];
    },

    methods: {
        async onSubmit() {
            const {canSubmit} = this;
            if (!canSubmit) return;

            await Message.submit(this.formData);
            this.$success('お問い合わせを受け付けました。改めてご連絡させていただきますので今しばらくお待ちくださいませ。');

            setTimeout(() => {
                window.location.reload();
            }, 5000);
        },

        async onValuesChange(form, value, values) {
            this.formData = values;

            this.$nextTick(() => {
                this.form.validateFields({
                    first: true,
                    force: true
                }, err => {
                    this.canSubmit = !err;
                });
            });
        },

        getRules(field) {
            let rules = [];
            switch (field) {
                case 'userName':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'}
                    ];
                    break;
                case 'email':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {type: 'email', message: 'メールのフォーマットが正しくありません'}
                    ];
                    break;
                case 'askType':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'}
                    ];
                    break;
                case 'content':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'}
                    ];
                    break;
                default:
                    break;
            }

            return rules;
        }
    }
};
</script>
<style lang="scss" scoped>
.message-box {
    flex: 1;
    display: flex;
    margin-bottom: -1rem;

    &::after {
        content: "";
        display: block;
        flex: 1;
        background: url("../login/img/group.jpg") no-repeat center / cover;
        padding-top: 58.2%;
    }

    .message-left {
        flex: 0 1 50%;
        font-family: "Noto Sans JP";
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.22rem;
        color: $c-02;
        padding: 0.6rem 1.6rem 2.4rem 1.2rem;

        .btn {
            border-radius: 0.12rem;
            margin-top: 0.4rem;
            width: 3rem;
            height: 0.52rem;
            background-color: $c-06;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Noto Sans JP";
            font-size: 0.16rem;
            font-weight: 700;
            line-height: 1;
            cursor: pointer;
        }

        .active {
            color: $c-09;
            background-color: $c-01;
        }
    }
}
</style>
