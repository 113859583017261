<!--
 * @Author: zhaoyang
 * @Date: 2024-11-07 20:23:46
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-20 10:54:06
-->

<template>
    <div
        id="app"
        class="app"
    >
        <!--insta-header></insta-header-->
        <nav-bar />
        <div :class="{'app-content': true, 'app-content-sticky': hideNav}">
            <router-view />
        </div>

        <back-btn />

        <!--insta-footer></insta-footer-->
        <footer-bar />

        <div
            v-show="isRequesting"
            class="indicator-mask"
        >
            <div class="instacash-indicator">
                <div class="indeterminate" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import {axios as http} from '@yqg/resource';

import Vue from 'ssr-common/vue';

import component from 'instacash/common/component';

import statusFlow from 'src/common/mixin/statusFlow';
import User from 'src/common/resource/user';
import {updateToken} from 'src/common/util';

Vue.use(component);

const UNKNOWN_ERROR = 'Unknown Error';

export default {
    name: 'Instacash',

    mixins: [statusFlow],

    data() {
        return {
            reqCount: 0
        };
    },

    computed: {
        ...mapGetters('finance', ['token', 'status']),

        isRequesting() {
            const vm = this;

            return vm.reqCount > 0;
        },

        hideNav() {
            return this.$route.meta.hideNav;
        }
    },

    beforeMount() {
        this.initHttpInterceptor();
    },

    async mounted() {
        const {$route: {name, query: {emailToken, expireTime}}} = this;

        if (emailToken && expireTime) {
            if (Date.now() > expireTime) {
                this.$router.replace({
                    name: 'Login'
                });
                this.$error('認証用URLが有効期限を超えました。');

                return;
            }
        }

        if (emailToken) {
            if (name === 'ChangePwd') {
                updateToken(emailToken);

                await this.$store.dispatch('finance/fetchInfo');

                return;
            }

            const hadLogin = localStorage.getItem('token');

            if (hadLogin) {
                if (name === 'RegisterStep1-2') {
                    await this.checkEmailTokenForRegister();
                }

                if (name === 'Profile') {
                    await this.checkEmailTokenForModifyEmail();
                }

                this.$store.dispatch('finance/fetchInfo');

                return;
            }

            this.$router.push({
                name: 'Login',
                query: {emailToken, from: name}
            });

            return;
        }

        await this.$store.dispatch('finance/fetchInfo');

        if (this.status) {
            if (['Login', 'RegisterStep1-1'].includes(name)) {
                this.$router.replace({name: 'Index'});

                return;
            }

            if (/Register/.test(name)) {
                this.goPage();
            }
        }
    },

    methods: {
        async checkEmailTokenForRegister() {
            const {query: {emailToken}} = this.$route;
            if (emailToken) {
                try {
                    const {data: {body: {emailTokenExpire}}} = await User.confirm({params: {emailToken}});
                    this.$store.commit('finance/SAVE', {emailTokenExpire});
                    // updateToken(token);
                    if (!emailTokenExpire) {
                        this.$router.replace({
                            name: 'RegisterStep2-1'
                        });

                        return;
                    }
                } catch (e) {
                    /* handle error */
                }
            }
        },

        async checkEmailTokenForModifyEmail() {
            const {query: {emailToken}} = this.$route;
            if (!emailToken) {
                return;
            }

            try {
                const {data: {body: {emailTokenExpire}}} = await User.updateEmailConfirm({params: {emailToken}});
                if (emailTokenExpire) {
                    this.$error('認証用URLが有効期限を超えました、マイページで再度にご修正ください。');
                }
            } catch (e) {
                /* handle error */
            }
        },

        initHttpInterceptor() {
            const vm = this;
            http.interceptors.request.use(
                req => {
                    if (!req.noToken) {
                        req.headers.token = localStorage.getItem('token') || '';
                    }

                    vm.reqCount += 1;

                    return req;
                },
                err => {
                    this.reqCount = Math.min(0, vm.reqCount);

                    return Promise.reject(err);
                }
            );
            http.interceptors.response.use(
                res => {
                    vm.reqCount = Math.min(0, vm.reqCount);
                    const {hideLoading, responseType} = res.config;
                    const {status = {}} = res.data;
                    const {code, detail = UNKNOWN_ERROR} = status;

                    if (responseType === 'blob') {
                        return res;
                    }

                    if (code === 0) { // api 正常返回
                        return res;
                    }

                    // 未登录或者登录过期
                    if (code === 1100) {
                        const {$route: {name, meta, query: {emailToken, expireTime}}} = this;
                        const token = localStorage.getItem('token');

                        if (name !== 'Login' && (meta.needLogin || token)) {
                            updateToken('');
                            vm.$router.replace({name: 'Login'});
                        }

                        if (emailToken && expireTime) {
                            this.$error('パスワードの再設定ができませんでした、再度お試しください。');
                        }
                    } else if (!hideLoading) {
                        this.$error(detail);
                    }

                    return Promise.reject(res);
                },

                err => {
                    this.reqCount = Math.min(0, vm.reqCount);

                    return Promise.reject(err);
                }
            );
        }
    },
};
</script>

<style lang="scss">
@import "../common/style/variables.scss";
@import "../common/style/index.scss";

.app {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &-content {
        padding-top: 0.8rem;
        flex: 1;
        background: $c-08;
        padding-bottom: 1rem;
    }

    &-content-sticky {
        padding: 0;
        display: flex;
    }

    .indicator-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);

        .instacash-indicator {
            position: relative;
            display: block;
            width: 100%;
            height: 3px;
            overflow: hidden;
            background-color: #000;
            background-clip: padding-box;
            border-radius: 2px;
        }

        .indeterminate {
            background-color: #fff;

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                content: "";
                background-color: inherit;
                animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
                will-change: left, right;
            }

            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                content: "";
                background-color: inherit;
                animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
                animation-delay: 1.15s;
                will-change: left, right;
            }
        }
    }
}

.b-toast {
    @media screen and (min-width: 500px) {
        right: 0;
        left: 0;
        margin: 0 auto;
        max-width: 15vw;
        padding: 10px;
    }
}

@keyframes indeterminate {
    0% {
        right: 100%;
        left: -35%;
    }

    60% {
        right: -90%;
        left: 100%;
    }

    100% {
        right: -90%;
        left: 100%;
    }
}

@keyframes indeterminate-short {
    0% {
        right: 100%;
        left: -200%;
    }

    60% {
        right: -8%;
        left: 107%;
    }

    100% {
        right: -8%;
        left: 107%;
    }
}
</style>
