var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "success-box" }, [
    _c("div", { staticClass: "success-left" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            パスワードのリセットが成功しました\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(
          "\n            パスワードのリセットに成功しました。新しいパスワードを使用して再度ログインしてください\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btn", on: { click: _vm.onGo } }, [
        _vm._v("\n            ログイン\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }