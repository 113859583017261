var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container about-us" }, [
    _c("h1", { staticClass: "bold" }, [_vm._v("\n        会社概要\n    ")]),
    _vm._v(" "),
    _c("div", { staticClass: "intro" }, [
      _c(
        "div",
        { staticClass: "des-group" },
        _vm._l(_vm.infos, function (ref, idx) {
          var label = ref[0]
          var value = ref[1]
          return _c("div", { key: idx, staticClass: "des-group-row b3" }, [
            _c("div", { staticClass: "des-group-row-label bold" }, [
              _vm._v(
                "\n                    " + _vm._s(label) + "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "des-group-row-value" }, [
              _vm._v(
                "\n                    " + _vm._s(value) + "\n                "
              ),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("p", { staticClass: "b2" }, [
        _vm._v(
          "\n            一般社団法人第二種金融商品取引業協会加入\n        "
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "b1 bold" }, [
        _vm._v("\n            沿革\n        "),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "b2" }, [
      _vm._v(
        "\n            一般社団法人第二種金融商品取引業協会を通じて契約する指定紛争解決機関"
      ),
      _c("br"),
      _vm._v(
        "\n            特定非営利活動法人証券・金融商品あっせん相談センター"
      ),
      _c("br"),
      _vm._v("\n            東京都中央区日本橋茅場町2-1-1第二証券会館"),
      _c("br"),
      _vm._v("\n            電話番号：0120－64－5005"),
      _c("br"),
      _vm._v("\n            受付け時間：土日祝日を除く9:00～17:00\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "b2" }, [
      _vm._v("\n            平成29年8月22日 会社設立"),
      _c("br"),
      _vm._v(
        "\n            令和6年10月10日金融商品取引業登録 関東財務局長（金商）第3435号\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }