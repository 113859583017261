<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-24 12:49:00 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 11:15:56 -->

<template>
    <div class="container register-kyc-code">
        <h2>郵便でお知らせした認証コ-ドをご入力下さい。</h2>
        <div class="input-wrapper">
            <input
                v-model="kycCode"
                :class="{'caret-hide': kycCode.length === 6}"
                type="text"
                autofocus
                maxlength="6"
            >
            <div class="input-bg" />
            <div class="input-bg" />
            <div class="input-bg" />
            <div class="input-bg" />
            <div class="input-bg" />
            <div class="input-bg" />
        </div>

        <div
            v-if="kycInvalid"
            class="input-tip b3"
        >
            認証コードが正しくありません。
        </div>

        <div class="btn-wrapper">
            <button
                class="b2 bold"
                :disabled="kycCode.length !== 6"
                @click="send"
            >
                認証
            </button>
        </div>
    </div>
</template>

<script>
import User from 'src/common/resource/user';

export default {
    name: 'RegisterKycCode',

    data() {
        return {
            kycCode: '',
            kycInvalid: false
        };
    },

    methods: {
        async send() {
            const {kycCode: kyc} = this;
            const {data: {body}} = await User.kycConfirm({kyc});
            if (body) {
                await this.$store.dispatch('finance/fetchMainInfo');
                this.nextStep();

                return;
            }

            this.kycInvalid = true;
        },

        nextStep() {
            const {fromProduct, fromLogin, fromRegister, id} = this.$route.query;
            if (fromProduct) {
                this.$router.replace({name: 'Product', params: {id}});

                return;
            }

            if (fromRegister || fromLogin) {
                this.$router.replace({name: 'Index'});

                return;
            }

            this.$router.push({name: 'ProductList'});
        }
    }
};
</script>

<style lang="scss" scoped>
.register-kyc-code {
    padding-top: 1.12rem;
    text-align: center;

    .h2 {
        color: $c-01;
    }

    .input-wrapper {
        position: relative;
        margin-top: 0.69rem;
        width: 100%;
        height: 1.83rem;
        display: flex;
        justify-content: space-between;

        .input-bg {
            width: 1.83rem;
            height: 100%;
            background-color: $grey-01;
            border-radius: 0.16rem;

            &:nth-of-type(2),
            &:nth-of-type(6) {
                background-color: $green-01;
            }

            &:nth-of-type(4) {
                background-color: $yellow-01;
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            right: -1rem;
            bottom: 0;
            padding-left: 0.67rem;
            color: $grey-03;
            font-family: Noto serif JP;
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 1.83rem;
            letter-spacing: 1.64rem;
            border: none;
            outline: none;
            background-color: transparent;

            &.caret-hide {
                caret-color: transparent;
            }
        }
    }

    .input-tip {
        margin-top: 0.16rem;
        color: $red-02;
    }

    .btn-wrapper {
        margin-top: 1.17rem;
    }
}
</style>
