<!--
 * @Author: zhaoyang
 * @Date: 2024-11-14 16:45:42
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-20 19:09:20
-->

<template>
    <div class="change-box">
        <div class="change-left">
            <div class="title">
                パスワード変更
            </div>
            <div
                v-if="form"
                class="form-wrap"
            >
                <a-form
                    class="form"
                    layout="vertical"
                    :form="form"
                >
                    <a-form-item
                        label="メールアドレス"
                    >
                        <a-input
                            v-decorator="['email', {initialValue: initialEmail}]"
                            disabled
                        />
                    </a-form-item>
                    <a-form-item
                        label="新しいパスワード"
                    >
                        <a-input
                            v-decorator="['password', {rules: getRules('password')}]"
                            type="password"
                            placeholder="パスワードを入力してください"
                        />
                    </a-form-item>
                    <a-form-item
                        label="新しいパスワード（確認）"
                    >
                        <a-input
                            v-decorator="['newPassword', {rules: getRules('passwordMust')}]"
                            type="password"
                            placeholder="パスワードを入力してください"
                        />
                    </a-form-item>

                    <div
                        class="btn"
                        :class="{active: canSubmit}"
                        @click="onSubmit"
                    >
                        変更を申請する
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

import Forget from 'instacash/common/resource/forget';

import statusFlow from 'src/common/mixin/statusFlow';

export default {
    name: 'ChangePwd',

    mixins: [statusFlow],

    data() {
        return {
            form: null,
            formData: null,
            canSubmit: false
        };
    },

    computed: {
        ...mapGetters('finance', ['email']),

        initialEmail() {
            return this.email ?? '';
        },

        emailToken() {
            return this.$route.query.emailToken ?? '';
        }
    },

    mounted() {
        const {onValuesChange} = this;

        this.form = this.$form.createForm(this, {
            name: 'change',
            onValuesChange
        });
    },

    methods: {
        ...mapActions('finance', {
            login: 'login',
            logout: 'logout'
        }),

        onValuesChange(form, value, values) {
            this.formData = values || {};

            this.$nextTick(() => {
                this.form.validateFields({
                    first: true,
                    force: true
                }, err => {
                    this.canSubmit = !err;
                });
            });
        },

        async onSubmit() {
            const {canSubmit, emailToken} = this;
            if (!canSubmit) return;

            const {formData: {password}} = this;

            await Forget.passwordConfirm({emailToken, password});

            await this.logout();

            this.$router.replace({
                name: 'ChangeSucc'
            });
        },

        getRules(field) {
            const pwdRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,16}$/;
            let rules = [];

            const isEqualValidatorGenerator = fieldKey => {
                return (rule, value, callback) => {
                    const {[fieldKey]: must} = this.formData || {};
                    if (must && value !== must) {
                        callback(' ');

                        return;
                    }

                    callback();
                };
            };

            switch (field) {
                case 'password':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {pattern: pwdRe, message: '大文字・小文字・数字をそれぞれ1字以上入れてください。'}
                    ];
                    break;
                case 'passwordMust':
                    rules = [
                        {required: true, message: '必須項目を記入してください。'},
                        {pattern: pwdRe, message: '大文字・小文字・数字をそれぞれ1字以上入れてください。'},
                        {validator: isEqualValidatorGenerator('password'), message: 'パスワードが違います。'}
                    ];
                    break;
                default:
                    break;
            }

            return rules;
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep {
    label[for="change_email"] {
        color: #b9b9bd;
    }
}

.change-box {
    flex: 1;
    display: flex;

    &::after {
        content: "";
        display: block;
        flex: 1;
        background: url("./img/group.jpg") no-repeat center / cover;
        padding-top: 58.2%;
    }
}

.change-left {
    flex: 0 1 50%;
    font-family: "Noto Sans JP";
    font-size: 0.14rem;
    font-weight: 400;
    line-height: 0.22rem;
    color: $c-02;
    padding: 1.6rem 1.6rem 2.4rem 1.2rem;

    .title {
        font-family: "Noto serif JP";
        font-size: 0.32rem;
        font-weight: 500;
        line-height: 0.42rem;
        color: $c-01;
    }

    .form-wrap {
        margin-top: 0.6rem;

        .form {
            display: flex;
            flex-direction: column;

            .btn {
                border-radius: 0.12rem;
                margin-top: 0.4rem;
                width: 3rem;
                height: 0.52rem;
                background-color: $c-06;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Noto Sans JP";
                font-size: 0.16rem;
                font-weight: 700;
                line-height: 1;
                cursor: pointer;
            }

            .tip {
                color: #5c5c62;
                margin-top: 0.24rem;
                font-family: "Noto Sans JP";
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.224rem;
            }

            .active {
                color: $c-09;
                background-color: $c-01;
            }
        }
    }
}
</style>
