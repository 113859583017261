var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "header",
      class: { "nav-bar": true, "nav-bar-transparent": _vm.hideNav },
    },
    [
      _c("router-link", { attrs: { to: { name: "Index" } } }, [
        _c("h2", { staticClass: "bold" }, [
          _vm._v("\n            Instacash\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideNav,
              expression: "!hideNav",
            },
          ],
          staticClass: "routes",
        },
        [
          _c("router-link", { attrs: { to: { name: "Index" } } }, [
            _vm._v("\n            Home\n        "),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: { name: "AboutUs" } } }, [
            _vm._v("\n            会社概要\n        "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "user" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }