/*
 * @Author: huafengli
 * @Date: 2024-05-15 19:20:56
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-13 17:56:48
 */
import { mapGetters } from 'vuex';
export default {
    computed: Object.assign({}, mapGetters('finance', ['status'])),
    methods: {
        goPage() {
            const { status } = this;
            const { $route: { name, params } } = this;
            let toRoute;
            switch (status) {
                case 'TEMP_CREATE': // 临时会员
                    toRoute = 'RegisterStep1-2';
                    break;
                case 'KYC_WAIT_SUBMIT': // kyc 待提交
                    toRoute = 'RegisterStep2-1';
                    break;
                case 'KYC_IN_REVIEW': // 审核中
                case 'KYC_REFUSE': // 审核拒绝
                case 'KYC_WAIT_UPDATE': // kyc待修改
                    toRoute = 'RegisterStep3';
                    break;
                case 'KYC_PASS': //kyc通过
                case 'KYC_SEND': // kyc 已寄送
                    toRoute = 'RegisterKycCode';
                    break;
                default:
                    {
                        if (status) {
                            if (/Login|Register/.test(name)) {
                                toRoute = 'Index';
                            }
                        }
                        else {
                            toRoute = 'Login';
                        }
                    }
                    break;
            }
            if (toRoute && toRoute !== name) {
                let query = {};
                const isProduct = name === 'Product';
                if (isProduct) {
                    query = Object.assign({ fromProduct: '1' }, params);
                }
                if (name.includes('Register')) {
                    query = { fromRegister: '1' };
                }
                if (name === 'Login') {
                    query = { fromLogin: '1' };
                }
                this.$router.replace({ name: toRoute, query });
            }
        }
    }
};
