/*
 * @Author: huafengli
 * @Date: 2024-04-29 16:58:26
 * @Last Modified by: yuanyuanliu
 * @Last Modified time: 2024-12-17 17:36:11
 */
import Enum from '@yqg/enum';
import Common from 'src/common/resource/common';
// 密保问题
export const pwdQuestionEnum = {
    FAVORITE_FOOD: '好きな食べ物',
    HOBBY: '趣味',
    MOTHER_MAIDEN_NAME: '母の旧姓'
};
// 银行类型
export const bankTypeEnum = {
    ORDINARY_DEPOSIT: '普通',
    CURRENT: '当座'
};
// 投资年限
export const investmentYearsEnum = {
    YEARS_1_3: '１～３',
    YEARS_3_5: '３～５',
    YEARS_5_MORE: '５以上'
};
// 投资账户
export const investmentAccountEnum = {
    TRUE: '有',
    FALSE: '無'
};
// 投资风险
export const investmentRiskEnum = {
    TRUE: 'はい',
    FALSE: 'いいえ'
};
export const nationalityEnum = {
    JP_DOMESTIC: '日本国籍のお客様の場合',
    FOREIGNER: '外国籍のお客様の場合'
};
// 货币类型
export const currencyTypeEnum = {
    JAPANESE_YEN: '円貨'
};
export const productTypeEnum = {
    ANONYMOUS_AGREEMENT: '匿名組合契約型'
};
export const EnumDic = (name) => {
    return Enum.query(() => Common.enums({ params: { name } }), {
        queryOnce: true,
    });
};
export const ProductStatusEnum = {
    NOT_OPEN: '募集開始前',
    // RAISING: '募集中', // 募集中不展示标签
    DOWN: '募集終了',
    RAISED: '満額達成',
    EXPIRED: '募集期間終了'
};
// 投资经验
export const investmentExperienceEnum = EnumDic('investmentExperience');
// 职业
export const professionEnum = EnumDic('profession');
// 投资方案
export const investmentPlanEnum = EnumDic('investmentPlan');
// 投资目的：investmentPurpose
export const investmentPurposeEnum = EnumDic('investmentPurpose');
// 咨询类型
export const askTypeEnum = EnumDic('askType');
