/**
 * @Author: qingliu
 * @Date: 2018-07-19 17:46:41.048
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-05-09 18:07:07
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import 'ssr-common/util/report';

import Vue from 'vue';

import {initSentry} from '@shared/client/util/sentry';

import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';

import Antd from './common/component/antd';
import {rootTitle} from './common/constant/config';
import {createApp} from './main';

Vue.use(Antd);

const {router} = createClientEntry({createApp, rootTitle});

initSentry({
    router,
    dsn: 'https://2c710eb403b51dd41f37b8640240faf4@sentry.fintopia.tech/80'
});

if (module.hot) {
    module.hot.accept();
}
