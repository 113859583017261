var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-box" }, [
    _c("div", { staticClass: "message-left" }, [
      _vm.form
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c(
                "a-form",
                {
                  staticClass: "form",
                  attrs: { layout: "vertical", form: _vm.form },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "氏名（姓名）" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "userName",
                              {
                                initialValue: _vm.initialName,
                                rules: _vm.getRules("userName"),
                              },
                            ],
                            expression:
                              "['userName', {initialValue: initialName, rules: getRules('userName')}]",
                          },
                        ],
                        attrs: { "max-length": 70 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-form-item",
                    { attrs: { label: "メールアドレス" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "email",
                              {
                                initialValue: _vm.initialEmail,
                                rules: _vm.getRules("email"),
                              },
                            ],
                            expression:
                              "['email', {initialValue: initialEmail, rules: getRules('email')}]",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-form-item",
                    { attrs: { label: "問い合わせの種類" } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "askType",
                                { rules: _vm.getRules("askType") },
                              ],
                              expression:
                                "[\n                            'askType',\n                            { rules: getRules('askType') },\n                        ]",
                            },
                          ],
                        },
                        _vm._l(_vm.askTypeList, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.text) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-form-item",
                    { attrs: { label: "問い合わせの内容" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "content",
                              { rules: _vm.getRules("content") },
                            ],
                            expression:
                              "['content', {rules: getRules('content')}]",
                          },
                        ],
                        attrs: {
                          "auto-size": { minRows: 10 },
                          "max-length": 1000,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn",
                      class: { active: _vm.canSubmit },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("\n                    送信する\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }