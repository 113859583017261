<!--
 * @Author: zhaoyang
 * @Date: 2024-02-25 11:57:21
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-12 13:32:10
-->

<template>
    <div
        v-if="detail && detail.hideStatus === 'SHOW'"
        class="container product"
    >
        <div class="block-img">
            <div class="small-title">
                {{ detail.type | formatProductType }}
            </div>
            <div class="title">
                {{ detail.name }}
            </div>
            <div class="text-wrap">
                <div class="text">
                    目標利回り：{{ detail.interestRate | toFullWidth }}％／年
                </div>
                <div class="text">
                    運用期間：{{ detail.closedDays | toFullWidth }}日
                </div>
                <div class="text">
                    ファンド名：{{ detail.name }}（{{ detail.type | formatProductType }}）
                </div>
            </div>
            <div class="desc">
                <span class="desc-text">
                    <span class="text1">目標利回り</span>
                    <span class="text2">{{ detail.interestRate | toFullWidth }}％</span>
                    <span class="text1">/年</span>
                </span>
                <span class="desc-text second">
                    <span class="text1">運用期間</span>
                    <span class="text2">{{ detail.closedDays | toFullWidth }}</span>
                    <span class="text1">日</span>
                </span>
            </div>
        </div>

        <div class="wrap">
            <item-info
                v-for="item in productFields"
                :key="item.label"
                class="wrap-item"
                label-class="left"
                value-class="right"
                :item="item"
                :val-obj="detail"
            >
                <template #operatorUpdated.value>
                    インスタキャッシュ株式会社
                </template>
                <template #name.value>
                    {{ detail.name }}（{{ detail.type | formatProductType }}）
                </template>
                <template #applyStartTime.value>
                    {{ detail.applyStartTime | dateMonth }}～{{ detail.applyEndTime | dateMonth }}
                </template>
                <template #interestRate.value>
                    年{{ detail.interestRate | toFullWidth }}％（運用期間利回り{{ detail.returnRate | toFullWidth }}％）
                </template>
            </item-info>
        </div>

        <div class="agreement-wrap">
            <div class="agree-title">
                <span class="title-left">以下の同意事項を</span><span class="title-right">お読みいただき、同意してください。</span>
            </div>
            <div class="agree-desc">
                <img
                    class="check-icon"
                    :src="agreeDescIsCheck ? Select : NoSelect"
                    alt=""
                    @click="agreeDescIsCheck=!agreeDescIsCheck"
                >
                <div>契約締結前交付書面,匿名組合契約書を全て読み、出資に伴うリスクや出資契約の内容を全て理解し自らの意思および判断に基づき下記金額の出資を申し込みます。</div>
            </div>
            <div
                v-for="(item, idx) in agreementList"
                :key="idx"
                class="check"
            >
                <img
                    class="check-icon"
                    :src="item.isCheck ? Select : NoSelect"
                    alt=""
                    @click="onCheck(idx, item)"
                >
                <span class="check-text">{{ pdfDisplayNameMap[item.text] }}</span>
                <span
                    class="check-link"
                    @click="handleRead(idx)"
                >読む</span>
            </div>
        </div>

        <div class="money-wrap">
            <div class="title">
                出資申込金額
            </div>

            <div class="sub-title">
                （残り募集金額を超える額は申し込めません）
            </div>

            <div class="sub-title">
                （{{ detail.userMinAmount }}万円以上１万円単位）
            </div>

            <div class="input-wrap">
                <input
                    v-model="money"
                    type="number"
                    :max="detail.residueAmount"
                    :min="detail.userMinAmount"
                    placeholder="〇〇〇〇"
                    class="money-input"
                    :class="{error: noLess}"
                >
                <span class="big-tip">万円</span>
            </div>

            <div
                v-if="showTip"
                class="input-tip"
            >
                ファンド募集上限額とお客樣の出資上限を超えた金額は申し込めません
            </div>

            <div class="money-tip">
                <item-info
                    v-for="item in applyFields"
                    :key="item.label"
                    class="money-item"
                    label-class="left"
                    value-class="right"
                    :item="item"
                    :val-obj="detail"
                >
                    <template #raiseAmount.value>
                        {{ detail.raiseAmount | currencyFormat | toFullWidth }}万円（{{ detail.raiseRatio | toFullWidth }}％）
                    </template>
                </item-info>
            </div>
            <div
                v-if="detail.userMaxAmount"
                class="tip-desc"
            >
                「{{ userInfo.name }}様の出資申込可能額は{{ detail.userMaxAmount | currencyFormat | toFullWidth }}万円以下です。」
            </div>
        </div>

        <div class="btn-wrap">
            <div
                class="btn"
                :class="{active: canSubmit}"
                @click="handleSubmit"
            >
                申込
            </div>
        </div>
    </div>
    <i-empty v-else />
</template>

<script>
import {mapGetters} from 'vuex';

import {dateMonth} from 'ssr-common/util/date';

import ItemInfo from 'instacash/common/component/finance/item-info';
import {pdfDisplayNameMap} from 'instacash/common/constant';
import NoSelect from 'instacash/common/image/no-select.svg';
import Select from 'instacash/common/image/select.svg';
import Order from 'instacash/common/resource/order';
import Product from 'instacash/common/resource/product';

import statusFlow from 'src/common/mixin/statusFlow';

import {All_DOWN_STATUS} from './constant';
import {productFields, applyFields} from './field';

export default {
    name: 'FinanceProduct',

    components: {
        ItemInfo
    },

    filters: {
        dateMonth,
    },

    mixins: [statusFlow],

    data() {
        const {params: {id}} = this.$route;

        return {
            productId: id,
            detail: null,
            hasTouch: false,
            money: '',
            NoSelect,
            Select,
            pdfDisplayNameMap,
            agreeDescIsCheck: false,
            agreementList: [
                {
                    showCheck: false,
                    isCheck: false,
                    text: 'contract-before'
                },
                {
                    showCheck: false,
                    isCheck: false,
                    text: 'fund-risk'
                },
                {
                    showCheck: false,
                    isCheck: false,
                    text: 'anonymous'
                }
            ],
            productFields,
            applyFields
        };
    },

    computed: {
        ...mapGetters('finance', ['kyc', 'userInfo', 'status']),

        canSubmit() {
            const {money, showTip, agreementList, noLess, agreeDescIsCheck, detail} = this;
            const hadDown = All_DOWN_STATUS.includes(detail.showStatus);

            return !hadDown && !noLess && money && !showTip && agreementList.every(item => item.isCheck) && agreeDescIsCheck;
        },

        noLess() {
            const {hasTouch, detail} = this;
            if (!hasTouch && !this.money) {
                return false;
            }

            // 小于最低申入额度
            return this.money < detail?.userMinAmount || this.money.toString().includes('.');
        },

        showTip() {
            const {money, detail, status} = this;

            // 输入金额 大于用户最高申请额度或者产品剩余额度
            const limitUseUserMaxAmount = !status || (['TEMP_CREATE', 'KYC_WAIT_SUBMIT'].includes(status)); // 因为此时无法进行资产判定

            return (money > detail.userMaxAmount && !limitUseUserMaxAmount) || money > detail?.residueAmount;
        }
    },

    watch: {
        money() {
            this.hasTouch = true;
        }
    },

    mounted() {
        this.fetchProductDetail();
    },

    methods: {
        async fetchProductDetail() {
            const {productId} = this;
            const {data: {body}} = await Product.productDetail({params: {id: productId}});
            this.detail = body;
        },

        async handleSubmit() {
            if (!this.canSubmit) return;
            // 非kyc认证后不能购买
            if (!this.kyc) {
                this.goPage();

                return;
            }

            const {productId, money: orderAmount} = this;
            await Order.order({productId, orderAmount});

            this.$router.push({
                name: 'ProductRecord'
            });
        },

        onCheck(idx, item) {
            if (!item.showCheck) return;
            this.agreementList[idx].isCheck = !this.agreementList[idx].isCheck;
        },

        handleRead(idx) {
            this.agreementList[idx].showCheck = true;
            window.open(`/static/pdf/${this.agreementList[idx].text}.pdf`);
        }
    },
};
</script>

<style lang="scss" scoped>
.product {
    display: flex;
    flex-direction: column;
    align-items: center;

    .block-img {
        box-sizing: border-box;
        padding: 0.4rem 0.6rem 1.24rem;
        width: 12.5rem;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 0.16rem;
        background-image: url("./img/bg-s.png");
        background-size: 100% 100%;

        .small-title {
            margin-bottom: 0.16rem;
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: #cacaca;
        }

        .title {
            font-family: Noto serif JP;
            font-size: 0.32rem;
            font-weight: 700;
            line-height: 0.42rem;
            color: #fff;
        }

        .text-wrap {
            margin-top: 0.16rem;
            width: 6.3rem;

            .text {
                font-family: Noto Sans JP;
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.22rem;
                color: #fff;
            }
        }

        .desc {
            width: 7.8rem;
            box-sizing: border-box;
            border-radius: 0.12rem;
            padding: 0.1rem 0.42rem 0.16rem;
            background-color: $c-07;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);

            .desc-text {
                .text1 {
                    font-family: Noto Sans JP;
                    font-size: 0.16rem;
                    font-weight: 400;
                    line-height: 0.26rem;
                    color: #5f5b5a;
                }

                .text2 {
                    font-family: Noto serif JP;
                    font-size: 0.48rem;
                    font-weight: 700;
                    line-height: 0.62rem;
                    color: #32302f;
                }
            }

            .second {
                margin-left: 0.9rem;
            }
        }
    }

    ::v-deep .wrap {
        margin-top: 1.42rem;
        width: 6.8rem;

        .wrap-item {
            display: flex;
            margin-top: 0.12rem;

            .left {
                width: 1.75rem;
                font-family: Noto Sans JP;
                font-size: 0.14rem;
                font-weight: 700;
                line-height: 0.22rem;
                color: #32302f;
            }

            .right {
                flex: 1;
                margin-left: 0.4rem;
                font-family: Noto Sans JP;
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.22rem;
                color: #5f5b5a;
            }
        }
    }

    .agreement-wrap {
        width: 6.8rem;
        margin-top: 0.95rem;

        .agree-title {
            margin-bottom: 0.2rem;

            .title-left {
                font-family: Noto Sans JP;
                font-size: 0.16rem;
                font-weight: 400;
                line-height: 0.26rem;
            }

            .title-right {
                font-family: Noto Sans JP;
                font-size: 0.16rem;
                font-weight: 700;
                line-height: 0.26rem;
            }
        }

        .agree-desc {
            margin-top: 0.08rem;
            font-family: Noto Sans JP;
            font-size: 0.12rem;
            font-weight: 400;
            line-height: 0.19rem;
            color: #828180;
            cursor: pointer;
            display: flex;
        }

        .check-icon {
            margin-right: 0.08rem;
            width: 0.18rem;
            height: 0.18rem;
            flex-shrink: 0;
        }

        .check {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0.12rem;
            cursor: pointer;

            .check-text {
                font-family: Noto Sans JP;
                font-size: 0.12rem;
                font-weight: 400;
                line-height: 0.19rem;
                color: $c-02;
            }

            .check-link {
                font-family: Noto Sans JP;
                font-size: 0.12rem;
                font-weight: 700;
                line-height: 0.19rem;
                color: $green-03;
                margin-left: 0.08rem;
            }
        }
    }

    .btn-wrap {
        margin-top: 0.4rem;
        width: 6.8rem;

        .btn {
            border-radius: 0.12rem;
            width: 3rem;
            height: 0.52rem;
            background-color: $c-06;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Noto Sans JP;
            font-size: 0.16rem;
            font-weight: 700;
            line-height: 1;
            cursor: pointer;
        }

        .active {
            color: $c-09;
            background-color: $yellow-02;
        }
    }

    .money-wrap {
        border-radius: 0.16rem;
        width: 6.8rem;
        margin-top: 0.57rem;
        box-sizing: border-box;
        padding: 0.28rem 0.4rem 0.5rem;
        background-color: $c-07;

        .title {
            color: #000;
            font-family: Noto serif JP;
            font-size: 0.2rem;
            font-weight: 700;
            line-height: 0.26rem;
        }

        .sub-title {
            margin-top: 0.09rem;
            color: #4c4948;
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
        }

        .input-wrap {
            margin-top: 0.18rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .money-input {
                box-sizing: border-box;
                width: 5.04rem;
                height: 0.79rem;
                border-radius: 0.16rem;
                padding-left: 0.24rem;
                padding-right: 0.24rem;
                border: 1px solid #cacaca;
                font-family: Noto serif JP;
                font-size: 0.4rem;
                font-weight: 700;
                line-height: 0.52rem;
                color: #32302f;
                outline: none;

                &::placeholder {
                    color: #a7a7a7;
                }
            }

            .big-tip {
                font-family: Noto serif JP;
                font-size: 0.4rem;
                font-weight: 700;
                line-height: 1;
                color: #828180;
            }
        }

        .error {
            border-color: #f66c70;

            &:focus {
                border-color: #f66c70;
            }
        }

        .input-tip {
            margin-top: 0.16rem;
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: #f66c70;
        }

        .money-tip {
            margin-top: 0.28rem;

            ::v-deep .money-item {
                display: flex;
                margin-top: 0.12rem;

                .left {
                    width: 0.9rem;
                    font-family: Noto Sans JP;
                    font-size: 0.14rem;
                    font-weight: 700;
                    line-height: 0.22rem;
                    color: #32302f;
                }

                .right {
                    margin-left: 0.4rem;
                    font-family: Noto Sans JP;
                    font-size: 0.14rem;
                    font-weight: 400;
                    line-height: 0.22rem;
                    color: #5f5b5a;
                }
            }
        }

        .tip-desc {
            margin-top: 0.16rem;
        }
    }
}
</style>
